var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header-top"},[_c('h2',{staticClass:"view-title"},[_vm._v("Organizations")]),_c('v-text-field',{staticClass:"search",attrs:{"label":"Search","prepend-icon":"mdi-magnify","clearable":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search.search),callback:function ($$v) {_vm.$set(_vm.search, "search", $$v)},expression:"search.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"id","sort-desc":_vm.sort_desc,"footer-props":{ 'items-per-page-options': _vm.items_per_page },"options":_vm.options,"server-items-length":_vm.total,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.location,"target":"_blank"}},[_vm._v(_vm._s(item.location))])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.priority + ' capitalize'},[_vm._v(_vm._s(item.priority))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{class:item.status + ' capitalize'},[_vm._v(_vm._s(item.status))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',{staticClass:"cont-actions"},[_c('v-btn',{staticStyle:{"min-width":"20px !important","padding":"10px !important"},attrs:{"color":"primary","dense":"","outlined":""},on:{"click":function($event){return _vm.goToRoute('organization-users', { client_id: item.client_id })}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountGroup)+" ")])],1),_c('v-btn',{staticStyle:{"min-width":"20px !important","padding":"10px !important"},attrs:{"color":"info","dense":"","outlined":""},on:{"click":function($event){return _vm.goToRoute('organization-edit', { client_id: item.client_id })}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiClipboardEditOutline)+" ")])],1)],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }