<template>
  <div>
    <div class="header-top">
      <h2 class="view-title">Users</h2>
      <v-text-field
        label="Search"
        v-model="search.search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      sort-by="id"
      :sort-desc="sort_desc"
      :footer-props="{ 'items-per-page-options': items_per_page }"
      :options.sync="options"
      :server-items-length="total"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <img v-if="item.profile.avatar" class="avatar" :src="item.profile.avatar" :alt="item.first_name" />
        <img v-else class="avatar" :src="images.avatarImg" :alt="item.first_name" />
      </template>
      <template v-slot:[`item.first_name`]="{ item }">
        {{ item.profile.first_name }}
      </template>
      <template v-slot:[`item.last_name`]="{ item }">
        {{ item.profile.last_name }}
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <div class="col-view">
          <span class="role" v-for="role in item.roles" :key="role.id">{{ role.name }}</span>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="cont-actions">
          <v-btn
            color="primary"
            @click="goToPath('/support/organization/user/' + item.id)"
            outlined
            style="min-width: 20px !important; padding: 10px !important"
          >
            <v-icon>
              {{ icons.mdiAccountEdit }}
            </v-icon>
          </v-btn>
          <dialog-impersonate v-if="hasUserPermission('impersonate')" :email="item.email" />

          <!-- menu -->
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small icon v-bind="attrs" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(option, i) in actionOptions" :key="i" :to="option.route + '/' + item.id">
                <v-list-item-title>
                  <v-icon size="18" class="me-2">
                    {{ option.icon }}
                  </v-icon>
                  <span>{{ option.title }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import moment from 'moment'
import avatarImg from '@/assets/images/avatars/1.png'
import DialogImpersonate from '@/components/dialog/DialogImpersonate.vue'
import { useRouter } from '@core/utils'
import { mdiAccountConvert, mdiAccountEdit, mdiDotsVertical, mdiAccountSwitch, mdiTrendingUp } from '@mdi/js'
// store
import store from '@/store'

export default {
  components: {
    DialogImpersonate,
  },

  setup(props) {
    const { router } = useRouter()

    const loading = ref(false)
    const items = ref([])
    const total = ref(0)
    const headers = ref([
      { text: 'Photo', value: 'avatar', sortable: false },
      { text: 'Email', value: 'email', sortable: true },
      { text: 'First Name', value: 'profile.first_name', sortable: true },
      { text: 'Last Name', value: 'last_name', sortable: true },
      { text: 'Role', value: 'role', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false },
    ])
    const items_per_page = ref([5, 10, 25, 50, 100])
    const sort_desc = ref(true)
    const options = ref({})
    const errors = ref(null)
    const search = ref({
      search: '',
    })

    const actionOptions = [
      { title: 'Change Organization', icon: mdiAccountSwitch, route: '/support/organization/change' },
    ]

    // store getters
    const hasUserRole = role => {
      return store.getters['auth/hasUserRole'](role)
    }
    const hasUserPermission = permission => {
      return store.getters['auth/hasUserPermission'](permission)
    }
    const setSnackbar = async data => {
      return await store.dispatch('snackbar/set', data)
    }

    const getData = async () => {
      loading.value = true
      return await store
        .dispatch(
          'user/allUsers',
          new URLSearchParams({
            ...options.value,
            ...search.value,
          }).toString(),
        )
        .then(resp => {
          if (resp && resp.data) {
            items.value = resp.data.items
            total.value = resp.data.total
          }
        })
        .catch(error => {
          console.log(error)
          errors.value = error.response.data.error
        })
        .finally(() => {
          loading.value = false
        })
    }
    const formatDate = date => {
      return moment(date).fromNow()
    }
    const clearSearch = () => {
      search.value.search = ''
    }
    const showDialog = item => {
      dialog_delete.value.show = true
      dialog_delete.value.item = item
    }
    const googleLink = word => {
      return `https://www.google.com/search?q=${word}`
    }
    const goToPath = path => {
      router.push({ path })
    }
    const goToRoute = route => {
      router.push({ name: route })
    }

    watch(
      options,
      () => {
        getData()
      },
      { deep: true },
    )

    watch(
      search,
      () => {
        getData()
      },
      { deep: true },
    )

    return {
      loading,
      items,
      total,
      headers,
      items_per_page,
      sort_desc,
      options,
      errors,
      search,

      actionOptions,

      hasUserRole,
      hasUserPermission,

      getData,
      formatDate,
      clearSearch,
      showDialog,
      googleLink,
      goToPath,
      goToRoute,

      icons: {
        mdiAccountConvert,
        mdiAccountEdit,
        mdiDotsVertical,
        mdiTrendingUp,
      },
      images: {
        avatarImg,
      },
    }
  },
}
</script>

<style lang="css" scoped>
.col-view {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.role {
  /* padding: 5px 10px; */
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.v-data-table >>> td {
  min-width: 120px;
}
.avatar {
  max-width: 120px;
  max-height: 120px;
}

@media (max-width: 767px) {
  .header-actions {
    justify-content: center;
    align-items: center;
  }
  .header-top {
    justify-content: center;
    align-items: center;
  }
  .view-title {
    text-align: center;
  }
}
</style>
