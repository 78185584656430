<template>
  <div>
    <div class="header-top">
      <h2 class="view-title">Organizations</h2>
      <v-text-field
        label="Search"
        v-model="search.search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      sort-by="id"
      :sort-desc="sort_desc"
      :footer-props="{ 'items-per-page-options': items_per_page }"
      :options.sync="options"
      :server-items-length="total"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.location`]="{ item }">
        <a :href="item.location" target="_blank">{{ item.location }}</a>
      </template>
      <template v-slot:[`item.priority`]="{ item }">
        <span :class="item.priority + ' capitalize'">{{ item.priority }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span :class="item.status + ' capitalize'">{{ item.status }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <div class="cont-actions">
            <v-btn
              color="primary"
              dense
              outlined
              @click="goToRoute('organization-users', { client_id: item.client_id })"
              style="min-width: 20px !important; padding: 10px !important"
            >
              <v-icon> {{ icons.mdiAccountGroup }} </v-icon>
            </v-btn>
            <v-btn
              color="info"
              dense
              outlined
              @click="goToRoute('organization-edit', { client_id: item.client_id })"
              style="min-width: 20px !important; padding: 10px !important"
            >
              <v-icon> {{ icons.mdiClipboardEditOutline }} </v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import { mdiAccountGroup, mdiClipboardEditOutline } from '@mdi/js'
//components
import DialogDelete from '@/components/dialog/DialogDelete.vue'

export default {
  components: {
    DialogDelete,
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      headers: [
        { text: 'Client Id', value: 'client_id', sortable: true },
        // { text: 'Email', value: 'email', sortable: true },
        { text: 'Company Name', value: 'company_name', sortable: true },
        { text: 'Website', value: 'website', sortable: true },
        { text: 'Service', value: 'service', sortable: true },
        { text: 'created_at', value: 'created_at', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items_per_page: [5, 10, 25, 50, 100],
      sort_desc: true,
      options: {},
      search: {
        search: '',
      },
      dialog_delete: {
        item: {},
        show: false,
      },
      icons: {
        mdiAccountGroup,
        mdiClipboardEditOutline,
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getList()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      getOrganizations: 'client/getOrganizations',
    }),
    async getList() {
      this.loading = true
      await this.getOrganizations(
        new URLSearchParams({
          ...this.options,
          ...this.search,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.items = resp.data.items
            this.total = resp.data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      return moment(date).fromNow()
    },
    clearSearch() {
      this.search.search = ''
    },
    goToPath(path) {
      this.$router.push({ path })
    },
    goToRoute(name, params = null) {
      this.$router.push({ name: name, params: params })
    },
  },
}
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 200px !important;
  word-break: break-all;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}

.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.v-data-table >>> td {
  min-width: 120px;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
}
.domain-list {
  display: flex;
  flex-direction: column;
}
.high {
  color: var(--v-danger-base);
}
.medium {
  color: var(--v-blue-base);
}
.low {
  color: var(--v-teal-base);
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px;
  }
  .url-domain {
    min-width: 300px;
  }
}

@media (max-width: 767px) {
  .header-actions {
    justify-content: center;
    align-items: center;
  }
  .header-top {
    justify-content: center;
    align-items: center;
  }
  .view-title {
    text-align: center;
  }
}
</style>
