<template>
  <div class="d-flex flex-column py-2">
    <div>
      <organization-table />
    </div>
    <div class="mt-6">
      <user-table />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import OrganizationTable from './components/OrganizationTable'
import UserTable from './components/UserTable'
//components
import Loader from '@/components/spinner/Loader.vue'

export default {
  components: {
    OrganizationTable,
    UserTable,
  },
  setup(props) {
    return {}
  },
}
</script>
