<template>
  <div class="wait">
    <v-progress-circular indeterminate color="primary" size="60"></v-progress-circular>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="css" scoped>
.wait {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
</style>

